:root {
    --bg: rgb(250,235,215); /*antiquewhite*/
    --bg2: antiquewhite;
    --bg-trans: rgba(255,255,255,0.5);
    --theme: lightblue;
    --theme-var: blue;
    --theme2: orange;
    --bg-gradient: linear-gradient(170deg, var(--bg) 0%, var(--theme) 100%);
    --theme-gradient: linear-gradient(170deg, var(--theme) 0%, var(--bg) 50%, #FFD080 100%);
    --hero-gradient: linear-gradient(170deg, var(--theme) 0%, var(--bg) 50%, #FFD080 100%);
    --text: black;
    --border: grey;
    --sun: rgba(255,255,255,0.2);
    --shade: rgba(0,0,0,0.2);
    --elem-grad: linear-gradient(170deg, var(--shade) 0%, var(--sun) 100%);
    --border-grad: linear-gradient(170deg, var(--sun) 0%, var(--shade) 100%);
    --inner-highlight: inset -3px -3px 6px rgba(255,255,255,0.2);
    --inner-shadow: inset 3px 3px 6px rgba(0,0,0,0.0.1);
    --drop-shadow: 5px 5px 10px rgba(0,0,0,0.3);
    --outline: rgba(0,0,0,0.3);
    --inset-opac: 0.3;
    --line-gradient: linear-gradient(170deg, orange 0%, rgba(240,240,0) 50%, orange 100%);
    --wave1-1: #FFD080;
    --wave1-2: red;
    --wave2-1: #FFD080;
    --wave2-2: #5064ff;
    /*--particles: rgb(255,255,255);*/
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'), url(./fonts/Nunito-VariableFont_wght.ttf) format('truetype');
    font-weight: 100 900;
    font-style: normal;
    font-display: swap;
}

/* GLOBAL */

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html, body, #root, .App {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
    font-family: 'Nunito';
    color: var(--text);
}

#modal {
    z-index: 99;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.modal-bg {
    background-color: rgba(0,0,0,0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.modal-container {
    position: relative;
    padding: 2em;
    z-index: 1;
    background-color: var(--bg);
    border: 1px solid black;
    color: var(--text);
    border-radius: 10px;
    transform-origin: top left;
}

#root {
    perspective: 1000px;
    perspective-origin: center;
    background: var(--bg-gradient);
    transition: background 0.5s;
}

.App {
    position: relative;
    transition: transform 0.75s;
    transform-origin: bottom;
    will-change: transform;
    transform-style: preserve-3d;
}

.App.flat {
    transform: rotateX(120deg);
}

button {
    background-color: transparent;
    border: 1px solid var(--border);
    font-family: inherit;
    font-size: 1.2em;
    font-weight: 500;
    border-radius: 10px;
    color: var(--text);
}

button, a, .btn-wrap, .button {
    cursor: pointer;
}

span/*, svg*/ {
    pointer-events: none;
    user-select: none;
}

a {
    text-decoration: none;
    color: inherit;
}

ul li {
    list-style-type: none;
}

ol {
    list-style-position: inside;
}

ol li {
    list-style-type: disc;
}

h1, h2, h3, h4, h5, h6 {
    text-align: center;
}

p {
    /*white-space: pre-line;*/
}

p:not(:nth-of-type(1)) {
    margin-top: 0.5em;
}

#svg-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(0);
    transition: transform 0.5s;
    z-index: 0;
}

/* GLOBAL FANCY */

.inset::before {
    position: absolute;
    margin: 1px 1px 0 1px;
    content: attr(title);
    color: var(--bg);
    opacity: var(--inset-opac);
}

button:hover .inset::before {
    opacity: 0;
}

/* LAYOUT */

.main {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    /*padding-top: 10px;*/
    z-index: 1;
}

.section {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: absolute;
    padding-top: 90px;
    top: 0;
    left: 0;
    overflow-y: auto;
    scrollbar-width: thin;
    /*will-change: opacity, transform;*/
}

    .section.show {
        transform: scale(1);
        opacity: 1;
    }

.article {
    height: 100%;
    margin: 0 auto 0 auto;
    overflow-y: auto;
    scrollbar-width: thin;
}

.heading {
    margin-bottom: 0.75em;
}

.tab-pan-btn {
    position: fixed;
    top: 50%;
    width: 60px;
    height: 60px;
    border: none;
    color: var(--text);
    opacity: 0.2;
    transform: translateY(-50%);
    z-index: 3;
    transition: opacity 0.5s;
}

.tab-pan-btn:hover {
    opacity: 0.5;
}

    .tab-pan-btn.disable {
        opacity: 0;
        pointer-events: none;
    }

#sidebar-btn {
    display: none;
    position: fixed;
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
    background: var(--bg-gradient);
    border: 1px solid rgba(0,0,0,0.2);
    box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
    z-index: 7;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1200px) {
    .tab-pan-btn {
        display: none;
    }
}

@media only screen and (max-width: 940px) {
    #sidebar-bg, #sidebar, #sidebar-btn {
        display: block;
    }

    /*I Header.css*/
    /*.btns-center {
        display: none;
    }*/

    /*.main {
        padding-top: 30px;
        height: 100%;
    }*/
}


/* HIDING */ 
.hide {
    opacity: 0;
}

.fade {
    opacity: 0;
}
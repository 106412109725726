.header {
    position: absolute;
    width: 100%;
    height: 60px;
    background-color: transparent;
    z-index: 4;
}

.btns-center {
    height: inherit;
    width: 800px;
    margin: auto;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
}

.btns-center-wrap {
    position: relative;
    transform: scaleX(1);
    transition: all 1s;
}

.header-btns {
    position: relative;
}

    .btns-center-wrap.init {
        transform: scaleX(0);
    }

    .btns-center-wrap::before, .btns-center-wrap::after {
        content: '';
        position: absolute;
        top: -3px;
        pointer-events: none;
        border-radius: 0 0 20px 20px;
        transform: perspective(15em) rotateX(-30deg) translateZ(0px);
    }

    .btns-center-wrap::before {
        right: -10%;
        bottom: -15px;
        left: -10%;
        background: var(--bg-gradient);
        border-radius: 0 0 20px 20px;
        box-shadow: var(--drop-shadow);
    }

    .btns-center-wrap::after {
        right: -9%;
        bottom: -10px;
        left: -9%;
        background: linear-gradient(170deg, var(--sun) 0%, var(--shade) 100%);
    }

        .header .btn-wrap {
            display: inline-block;
            position: relative;
            margin: auto 0;
            width: 200px;
            height: 100%;
            vertical-align: middle;
        }

    .header .btn-wrap .header-btn {
        position: relative;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        left: 2px;
        top: 2px;
        padding: 0.5em;
        border: none;
    }

.header-btn span {
    display: inline;
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 1em;
    line-height: 1em;
    overflow: visible;
    z-index: 3;
    text-rendering: optimizeLegibility;
    vertical-align: middle;
}

        .header .btn-wrap .header-btn::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: inherit;
            pointer-events: none;
        }

.dm-btn-outer {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 40px;
    cursor: pointer;
    border-radius: 100px;
    box-shadow: 0 0 5px transparent;
    transition: box-shadow 0.5s;
}

.dm-btn-outer:hover .switch {
    box-shadow: 0 0 5px var(--theme2);
}

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--bg-gradient);
    border-radius: 34px;
    box-shadow: 1px 1px 3px var(--shade);
    -webkit-transition: .4s;
    transition: .4s;
}

    .slider:before {
        position: absolute;
        content: '';
        height: 30px;
        width: 30px;
        left: 8px;
        top: 5px;
        background-color: var(--bg);
        border-radius: 50%;
        box-shadow: 0 0 1px rgba(0,0,0,0.2), 1px 1px 3px var(--shade);
        -webkit-transition: .4s;
        transition: .4s;
        z-index: 2;
    }

input:checked + .slider {
    border: 1px solid var(--outline);
}

input:focus + .slider {
    /*box-shadow: 0 0 1px var(--theme);*/
}

input:checked + .slider:before {
    transform: translateX(34px);
    box-shadow: 1px 1px 3px var(--sun);
    /*box-shadow: 0 0 0 transparent;*/
}

.dm-icon {
    color: rgb(240,240,40);
    font-size: 22px;
    position: absolute;
    height: 100%;
    z-index: 1;
    filter: drop-shadow(1px 1px 2px rgba(0,0,0,0.3)) drop-shadow(0 0 1px rgba(0,0,0,0.3));
}

#section-indicator {
    position: absolute;
    width: 100px;
    height: 5px;
    bottom: 6px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 10px;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
    transition: transform 0.3s;
}

    #section-indicator::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--line-gradient);
        border-radius: inherit;
    }


@media only screen and (max-width: 940px) {
    .btns-center {
        display: none;
    }

    .header {
        /*height: 60px;*/
        pointer-events: none;
    }

    .dm-btn-outer {
        pointer-events: all;
    }

    .main {
        /*height: calc(100% - 60px);*/
        padding-top: 0;
    }
}

.hero-container {
    --hero-color1: white;
    --hero-color2: rgb(80,100,255);
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(0vh);
    transition: transform 1.5s, opacity 1.5s;
    z-index: 10;
}

#hero-content {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    transform: translateY(0vh);
    transition: transform 1.5s, opacity 1.5s;
}

.hero-container h1, .hero-container h2, #hero-action-wrap {
    width: 100%;
}

.hero-container h1 {
    color: var(--hero-color1);
    margin-top: 40vh;
    text-shadow: 0 0 4em rgba(0,0,0,0.5), 0.1em 0.1em 0.4em rgba(0,0,0,0.25), 0 0 3px rgba(0,0,0,1);
}

.hero-container h2 {
    color: var(--hero-color2);
    margin-top: 20px;
    text-shadow: 0.1em 0.1em 0.4em rgba(255,255,255,0.5);
}

#hero-action-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

    #hero-action-wrap button {
        margin-top: 60px;
        padding: 10px 20px;
        background-color: var(--bg);
        background: var(--theme-gradient);
        background-size: 500%;
        background-position: 0% 50%;
        box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.3);
        transform: scale(1);
        transition: transform 0.5s cubic-bezier(.5, -.5, .5, 3), background-position 1s;
    }

        #hero-action-wrap button:hover {
            transform: scale(1.05);
            background-position: 90%;
        }

#hero-scroll {
    display: block;
    position: absolute;
    margin: auto;
    left: 50%;
    bottom: 80px;
    width: 180px;
    border: none;
    color: rgba(240,240,0,1);
    border-radius: 50%;
}

#hero-scroll svg {
    width: 100%;
    filter: drop-shadow(0 0 1px rgba(0,0,0,1));
}

#arrow-glow {
    position: absolute; 
    left: 0; 
    top: 0;
    animation: hero-glow 1s infinite linear;
    animation-direction: alternate;
}

#hero-scroll svg {
    fill: #ffbc5d;
    transition: fill 0.5s;
}

#hero-scroll:hover #arrow-glow {
    fill: #ffdb5d;
}

@keyframes glow {
    0% {
        filter: blur(20px);
        transform: rotate(0deg);
    }
    100% {
        filter: blur(50px);
        transform: rotate(360deg);
    }
}

        @keyframes hero-glow {
            0% {
                filter: 
                /* outline */
                drop-shadow(0 0 1px rgba(0,0,0,1)) 
                drop-shadow(0.1em 0.1em 0.5em rgba(0,0,0,0.5)) 
                drop-shadow(0 0 0.5em rgba(253,240,100,0.5))
                drop-shadow(0 0 0.5em rgba(100,100,255,0.5))
                drop-shadow(0 0 0.5em rgba(253,187,200,0.5))
            }
            100% {
                filter: 
                /* outline */
                drop-shadow(0 0 1px rgba(0,0,0,1)) 
                drop-shadow(0.1em 0.1em 0.5em rgba(0,0,0,0.5)) 
                drop-shadow(0 0 1em rgba(253,187,100,1))
                drop-shadow(0 0 1em rgba(253,187,100,1))
                drop-shadow(0 0 2em rgba(253,187,100,1))
            }
        }

    /* BAGGRUND */
    .svg-bg {
        pointer-events: none;
    }

    .wave1, .wave2, .wave0 {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 130%;
        height: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
        z-index: 0;
    }

    .wave1 svg, .wave2 svg, .wave0 svg {
        position: relative;
        display: block;
        width: 130%;
    }

    .wave0 svg {
        opacity: 0.3;
        width: 100%;
        height: 40%;
        transform: translateX(0);
        transition: all 1s;
    }

    .wave1 svg {
        height: 90%;
        transition: all 1s;
        opacity: 0;
    }

    .wave2 svg {
        height: 85%;
        transition: all 1s;
        opacity: 1;
    }

/* MEDIA QUERIES */
@media only screen and (max-width: 1280px) {
    .hero-container h1, .hero-container h2, #hero-action-wrap {
        width: 90%;
        margin-left: 5%;
    }

    #hero-scroll {
        bottom: 50px;
    }
}

@media only screen and (max-height: 700px) {
    .hero-container h1 {
        margin-top: 20vh;
    }
}

@media only screen and (max-height: 500px), only screen and (max-width: 400px) and (max-height: 700px) {
    #hero-scroll {
        display: none;
    }
    .hero-container h1 {
        margin-top: 40vh;
    }
}

@media only screen and (max-height: 400px) {
    .hero-container h1 {
        margin-top: 20vh;
    }
}

@media only screen and (max-width: 300px) {
    .hero-container h2 {
        display: none;
    }
}

@media only screen and (max-height: 260px) {
    .hero-container h1 {
        margin-top: 0;
    }
}
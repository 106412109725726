/* SIDE BAR */
#sidebar-bg {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    /*backdrop-filter: blur(2px);*/
    z-index: 8;
    opacity: 0;
    transition: opacity 0.5s;
}

    #sidebar-bg.open {
        opacity: 1;
    }



#sidebar {
    display: none;
    position: fixed;
    top: 0;
    right: -300px;
    bottom: 0;
    width: 100%;
    max-width: 300px;
    padding: 50px 40px;
    background-color: var(--bg);
    transform: translateX(0px);
    transition: transform 0.5s;
    z-index: 9;
    backface-visibility: hidden;
}

    #sidebar.open {
        transform: translateX(-300px);
    }

    #sidebar button {
        display: block;
        padding: 10px;
        border: none;
        transition: all 1s;
    }

        #sidebar button:not(button:last-child) {
            margin-bottom: 20px;
            font-size: 1.2em;
        }

#close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

    #close-btn svg {
        font-size: 1.7em;
    }

@media only screen and (max-width: 940px) {
    #sidebar-bg, #sidebar {
        display: block;
    }
}